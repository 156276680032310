import { tapestry } from "@/lib/constants/fonts";
import { Typography } from "@mui/material";
import Link from "next/link";

export const Logo = () => {
  return (
    <Link href="/">
      <Typography
        sx={{
          "fontWeight": 700,
          "fontFamily": tapestry.style.fontFamily,
          "fontSize": "2rem",
          "textShadow": "1px 1px 10px #000",
          "color": "primary.main",
          "&:hover": {
            color: "text.primary",
          },
        }}
      >
        APT
      </Typography>
    </Link>
  );
};
