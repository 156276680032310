"use client";

import { Close, Menu } from "@mui/icons-material";
import { Box, Container, Grid, IconButton } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import { useState } from "react";
import { Logo } from "./MainHeader/Logo";
import {
  ImpressumLink,
  InfoLink,
  MapsLink,
  PrivacyPolicyLink,
} from "./MainHeader/OtherLinks";

export const MainAppBar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <AppBar position="sticky" color="secondary" sx={{ zIndex: 6 }}>
      <Container maxWidth="xl">
        <Grid container alignItems="center" columnSpacing={2} padding={1}>
          <Grid
            item
            xs={12}
            md={3}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 3,
            }}
          >
            <Logo />

            <Box
              display={{
                xs: "flex",
                md: "none",
              }}
              sx={{
                alignItems: "center",
                gap: 0.2,
              }}
            >
              <InfoLink />
              <PrivacyPolicyLink />
              <ImpressumLink />

              <IconButton edge="end" onClick={() => setMenuOpen(!menuOpen)}>
                {menuOpen ? (
                  <Close fontSize="large" color="primary" />
                ) : (
                  <Menu fontSize="large" color="primary" />
                )}
              </IconButton>
            </Box>
          </Grid>
          <Grid
            item
            xs
            md={6}
            display={{
              xs: menuOpen ? "flex" : "none",
              md: "flex",
            }}
            sx={{
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
            }}
          >
            <MapsLink />
          </Grid>
          <Grid
            item
            xs="auto"
            md={3}
            display={{
              xs: "none",
              md: "flex",
            }}
            sx={{
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 0.2,
            }}
          >
            <PrivacyPolicyLink />
            <ImpressumLink />
          </Grid>
        </Grid>
      </Container>
    </AppBar>
  );
};
