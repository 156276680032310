import { aoboshiOne } from "@/lib/constants/fonts";
import { Map } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import Link from "next/link";

export const PrivacyPolicyLink = () => {
  return (
    <Link href="/privacy">
      <Typography
        sx={{
          "padding": 0.5,
          "fontSize": 12,
          "color": "primary.main",
          "fontFamily": aoboshiOne.style.fontFamily,
          ":hover": {
            color: "text.primary",
          },
        }}
      >
        Privacy Policy
      </Typography>
    </Link>
  );
};

export const ImpressumLink = () => {
  return (
    <Link href="/impressum">
      <Typography
        sx={{
          "padding": 0.5,
          "fontSize": 12,
          "color": "primary.main",
          "fontFamily": aoboshiOne.style.fontFamily,
          ":hover": {
            color: "text.primary",
          },
        }}
      >
        Impressum
      </Typography>
    </Link>
  );
};

export const InfoLink = () => {
  return (
    <Link href="https://maze.codes/projects" target="_blank">
      <Typography
        sx={{
          "padding": 0.5,
          "fontSize": 12,
          "color": "primary.main",
          "fontFamily": aoboshiOne.style.fontFamily,
          ":hover": {
            color: "text.primary",
          },
        }}
      >
        More Info
      </Typography>
    </Link>
  );
};

export const MapsLink = () => {
  return (
    <Link href="/maps">
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Map fontSize="large" />

        <Typography
          sx={{
            "padding": 0.5,
            "color": "primary.main",
            "fontFamily": aoboshiOne.style.fontFamily,
            ":hover": {
              color: "text.primary",
            },
          }}
        >
          Maps
        </Typography>
      </Box>
    </Link>
  );
};
